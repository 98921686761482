// import { Outlet } from "react-router-dom";
import Navlayout from "./topnav";
// import "../css/stylesdashboard.css";

const Dashboard = () => {
    return (
      <>
        <Navlayout />
        {/* <Footer /> */}
      </>
    )
  };
export default Dashboard