import React, { useState }  from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGithubSquare, faInstagramSquare, faLinkedin, faTelegramPlane, faTwitterSquare, faWhatsappSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import axios from "axios";
const endPoint = process.env.REACT_APP_BASE_URL;
import DocumentTitle from "../../components/DocumentTitle";


const image = 'https://images.unsplash.com/photo-1576267423445-b2e0074d68a4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'

const contentData = [
    {
        title: "About us ? ",
        // paragraph: " Over the years, access to education is reserved for the previlaged in society, even when civilization has reached almost every corner of our world. The quality of education varies from city to city with people in rural communities paying dearly for the inequality. And this is what Royal Institue of Science and Entrepreneurship seeks to resolve with an all in one virtual platform.",
        paragraph: "Over the years, access to education has remained a privilege largely reserved for the fortunate few, despite civilization's widespread reach across the globe. Disparities in educational quality persist, particularly affecting rural communities who bear the brunt of this inequality. At the Royal Institute of Science and Entrepreneurship, we are dedicated to addressing these challenges through our comprehensive virtual platform. Our mission is to democratize access to education by providing a unified virtual space where individuals from all backgrounds can access high-quality learning opportunities. By bridging the gap between urban and rural educational disparities, we aim to empower students with the knowledge and skills needed to succeed in an increasingly interconnected world. Through innovative teaching methods and a commitment to excellence, we strive to create a supportive learning environment that fosters academic achievement and personal growth. Join us at the Royal Institute of Science and Entrepreneurship as we redefine education and empower the next generation of leaders and innovators.",

        points: [
            {
                id: '01',
                title: "Basic and Secondary Tuition",
                description:
                    "We provide accessible opportunities for individuals aiming to enhance their performance in the West African Certificate Examinations (WAEC), offering both Remedials (Nov-Dec) and WASSCE options. Through our innovative online platform, we empower students to pursue the crucial certifications needed for higher education, fostering a supportive environment that promotes academic growth and success."
            },
            {
                id: '02',
                title: "Tertiary and Vocation Tuition",
                description: "We provide tertiary programs  that combines theoretical knowledge applicable in corporate environments, with vocational training aimed at imparting practical skills and fostering entrepreneurial spirit. It equips individuals with the intellectual foundation for policy development and decision-making in organizations, while also providing hands-on training essential for immediate application in various professions."
                //  Moreover, it nurtures entrepreneurial skills such as creativity, innovation, and business acumen, empowering students to contribute effectively to economic growth and societal development."

                // description: "Tertiary education harness the wide range of theorotical knowledge that can be applicable in cooperate environments for policy development and Vocational training to provide instant skills and entrepreneural backbone for all."
            },
            {
                id: '03',
                title: "Comprehensive Curriculum:",
                description: "We are committed to cultivating and nurturing the future leaders who not only embrace self-learning but excel in it. This dedication builds their mental fortitude to confidently challenge existing norms and thrive in an ever-changing technological era, where adaptability is paramount. Our aim is to empower them with the skills and mindset to navigate complex challenges independently, making meaningful contributions to their fields with creativity and innovation."
                // description: "Groom and nurture the next generation of leaders to enjoy self learning, a feet that will strenghten them mentally to challenge the status-quo to learn with little or no supervision in a revolving technological world."
            }

        ]
    }
];

const contactData = [
    {
        title: "Contact Information ",
        paragraph: " We are alwalys available to respond to your questions",

        points: [
            {
                id: '01',
                title: "Basic and Secondary Tuition",
                description:
                    "We provide accessible opportunities for individuals aiming to enhance their performance in the West African Certificate Examinations (WAEC), offering both Remedials (Nov-Dec) and WASSCE options. Through our innovative online platform, we empower students to pursue the crucial certifications needed for higher education, fostering a supportive environment that promotes academic growth and success."
            },
            {
                id: '02',
                title: "Tertiary and Vocation Tuition",
                description: "We provide tertiary programs  that combines theoretical knowledge applicable in corporate environments, with vocational training aimed at imparting practical skills and fostering entrepreneurial spirit. It equips individuals with the intellectual foundation for policy development and decision-making in organizations, while also providing hands-on training essential for immediate application in various professions."

            },
            {
                id: '03',
                title: "Comprehensive Curriculum:",
                description: "We are committed to cultivating and nurturing the future leaders who not only embrace self-learning but excel in it. This dedication builds their mental fortitude to confidently challenge existing norms and thrive in an ever-changing technological era, where adaptability is paramount. Our aim is to empower them with the skills and mindset to navigate complex challenges independently, making meaningful contributions to their fields with creativity and innovation."
            }

        ]
    }
];


const pageTitle = 'Support';
const pageDescription = 'The Royal College of Science and Entrepreneurship (RISE) is all in one institution that seeks to provide opportunity for everyone to learn'


function Support() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${endPoint}/openroute/support`, { name,email,contact, message });

            if (response.status === 200) {
                alert('Message sent successfully!');
                // Clear the input field after successful subscription
                setName('');
                setEmail(''); 
                setContact('');
                setMessage('');
            } else {
                alert('Message not sent. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };





    return (
        <div className="wrapper">
            <DocumentTitle title={pageTitle} description={pageDescription} />




            <div className='container mt-4 pb-5 mb-2'>
                <div className="row row-cols-2 cx">

                    <div className="col-6 col-rps ">

                        {contactData.map((section, index) => (
                            <div className="contact-info" key={index}>
                                <h2 className='start'>{section.title} </h2>
                                <p>{section.paragraph}</p>



                            </div>
                        ))}



                        <div className="">
                            <h2></h2>
                            <p><strong>Phone:</strong> +233 (0)556878617</p>
                            <p><strong>Email:</strong> support@royaise.com</p>
                        </div>

                        <div className="social-media">
                            <h2>Connect with Us</h2>


                            <div>
                                <Link to="/#facebook" className="social-icon">
                                    <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                                </Link>
                                <Link to="/#twitter" className="social-icon">
                                    <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
                                </Link>
                                <Link to="/#instagram" className="social-icon">
                                    <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
                                </Link>
                                <Link to="/#linkedin" className="social-icon">
                                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                </Link>
                                <Link to="/#youtube" className="social-icon">
                                    <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
                                </Link>


                                <Link to="/#whatsapp" className="social-icon">
                                    <FontAwesomeIcon icon={faWhatsappSquare} size="2x" />
                                </Link>


                                <Link to="/#telegram" className="social-icon">
                                    <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                                </Link>

                            </div>
                        </div>


                    </div>

                    <div className="col-6 col-rps  contact-info">

                        <h2> Send us a Message </h2>

                        <div className="card p-4">
                            <form onSubmit={handleSubmit}>
                                <input type="text" 
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={name} onChange={(e) => setName(e.target.value)}
                                 />
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                />

                                <input
                                    type="contact"
                                    id="contact"
                                    name="contact"
                                    placeholder="Phone number"
                                    value={contact} onChange={(e) => setContact(e.target.value)}
                                />

                                <textarea
                                    name="massage"
                                    placeholder="write your message"
                                    id="message"
                                    value={message} onChange={(e) => setMessage(e.target.value)}>

                                </textarea>

                                <button className="primary-button" type="submit" >Submit</button>



                            </form>

                        </div>
                    </div>
                </div>



            </div>


        </div>
    );
}

export default Support;



