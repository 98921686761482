import React from "react";
import DocumentTitle from "../../components/DocumentTitle";




const policyData = [
    {
        title: "Privacy policy ",
        intro: "Introduction",
        paragraph: " Royal Institute of Science and Entreprenuership also known as RISE ( we, our or us) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit and use our website as a student or instructor rise.com (the Site) and use our services (the Services). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.",

        points: [
            {
                t2: "1. Information We Collect",
                desc: "We may collect information about you in a variety of ways. The information we may collect on the Site includes:",
                details: [
                    {
                        id: '',
                        title: "Personal Information",
                        description: "Personally identifiable information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards."
                    },
                    {
                        id: '',
                        title: "Derivative Data",
                        description: "Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site."
                    },
                    {
                        id: '',
                        title: "Financial Data",
                        description: "Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site."
                    },
                    {
                        id: '',
                        title: "Social Networking Information",
                        description: "User information from social networking sites, such as Facebook, Google+, Instagram, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks."
                    }
                ]


            },

            {
                t2: "2. Use of  Information",
                // desc: "We use the information we collect in the following ways:",
                desc : (
                    <ul>
                        <p style={{marginLeft:"-20px"}}> We use the information we collect in the following ways:</p>
                        <li className="li">To provide, operate, and maintain our Services</li>
                        <li className="li" >To process your transactions and manage activities you perform</li>
                        <li className="li">To find and prevent fraud </li>
                        <li className="li">To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Site, and for marketing and promotional purposes </li>
                        <li className="li">To develop new products, services, features, and functionality</li>
                    </ul>
                ),

                details: [ ]
                
            },
            {
                t2 : "3. Disclosure of Your Information",
                desc: " We do not sell your valuable data to anybody, however, if neccessary, we may disclose your information in one or more of the following circumstance",
                details: [
                    {
                        id: '',
                        title: "By Law or to Protect Rights",
                        description: "If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation."
                    },
                    {
                        id: '',
                        title: "Third-Party Service Providers",
                        description: "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance."

                    },
                    {
                        id: '',
                        title: "Business Transfers",
                        description: "We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company."
                    },
                    {
                        id: '',
                        title: "Marketing Communications",
                        description: "With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law."
                    },
                ]

            }
        ]
    }
];


const pageTitle = 'Privacy-Policy';
const pageDescription = 'The Royal College of Science and Entrepreneurship (RISE) is all in one institution that seeks to provide opportunity for everyone to learn'


function Privacy() {

    return (
        <div className="wrapper">
            <DocumentTitle title={pageTitle} description={pageDescription} />



            <div className='container mt-4 pb-5 mb-2'>
                {policyData.map((section, index) => (
                    <div className="contact-info" key={index}>
                        <h2 className='start'>{section.title}</h2>
                        <strong className='start'>{section.intro}</strong>
                        <p>{section.paragraph}</p>
                        <br />

                        {section.points.map((point, idx) => (
                            <div key={idx}>
                                <strong className='start'>{point.t2}</strong>
                                <p>{point.desc}</p>
                                <strong>{point.title}</strong>

                                {point.details.map((detail, dIdx) => (
                                    <div key={dIdx}>
                                        <strong > {detail.id} {detail.title}</strong>
                                        <p className='text-justify'>{detail.description}</p>
                                    {/* <strong style={{}}>{detail.number}</strong> */}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>






        </div>
    );
}

export default Privacy;



