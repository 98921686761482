import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { fetchDataEndpoint, updateGuidanceData, addGuidanceData } from "../../api/StudentContact";
import DocumentTitle from "../../components/DocumentTitle";

const Contact = () => {

  const { currentUser } = useContext(AuthContext);
  const token = currentUser?.token;

  const [guidanceData, setGuidanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newGuidanceData, setNewGuidanceData] = useState({});

  useEffect(() => {
    const fetchGuidanceData = async () => {
      try {
        if (currentUser) {
          const guidanceData = await fetchDataEndpoint("/student/portal/contact", token);
          setGuidanceData(guidanceData);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGuidanceData();
  }, [currentUser, token]);

  const handleUpdateGuidance = async (updatedGuidanceData) => {
    try {
      setLoading(true);
      if (updatedGuidanceData.id) {
        await updateGuidanceData(updatedGuidanceData, token);
      } else {
        await addGuidanceData(updatedGuidanceData, token);
      }
      const refreshedGuidanceData = await fetchDataEndpoint("/student/portal/contact", token);
      setGuidanceData(refreshedGuidanceData);
      setIsEditing(false);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleAddNew = () => {
    setIsEditing(guidanceData.length); // Set index to add new item at the end of the list
    setNewGuidanceData({});
  };

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="container">
      <DocumentTitle title="Contact" />

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {guidanceData.map((data, index) => (
        <div key={index}>
          <ul>
            <li>
              <p>Name: {isEditing === index ? <input type="text" name="name" defaultValue={data.name} /> : data.name || "N/A"}</p>
              <p>Email: {isEditing === index ? <input type="text" name="email" defaultValue={data.email} /> : data.email || "N/A"}</p>
              <p>Contact : {isEditing === index ? <input type="text" name="contact" defaultValue={data.contact} /> : data.contact || "N/A"}</p>
              <p>Relationship: {isEditing === index ? <input type="text" name="relationship" defaultValue={data.relationship} /> : data.relationship || "N/A"}</p>
              <p>Address: {isEditing === index ? <input type="text" name="address" defaultValue={data.address} /> : data.address || "N/A"}</p>
              {isEditing === index && <button onClick={() => handleUpdateGuidance(data)}>Save</button>}
              {!isEditing && <button onClick={() => handleEdit(index)}>Edit emergency contact</button>}
            </li>
          </ul>
        </div>
      ))}
      {!isEditing && (
        <div>
          <p>No guidance information found. Please add emergency contact.</p>
          <div className="bg-light">
            <button onClick={handleAddNew}>Add New Guidance Information</button>
          </div>
        </div>
      )}
      {isEditing === guidanceData.length && (
        <div>
          <form onSubmit={(e) => {
            e.preventDefault();
            const newGuidanceData = {
              name: e.target.name.value,
              email: e.target.email.value,
              contact: e.target.contact.value,
              relationship: e.target.relationship.value,
              address: e.target.address.value,
            };
            handleUpdateGuidance(newGuidanceData);
          }}>
            <div className="form-group">
              <label htmlFor="name"> Name:</label>
              <input type="text" className="form-control" id="name" name="name" />
            </div>
            <div className="form-group">
              <label htmlFor="email"> Email:</label>
              <input type="text" className="form-control" id="email" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="contact">Contact:</label>
              <input type="text" className="form-control" id="contact" name="contact" />
            </div>
            <div className="form-group">
              <label htmlFor="contact">Relationship:</label>
              <input type="text" className="form-control" id="relationship" name="relationship" />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input type="text" className="form-control" id="address" name="address" />
            </div>
            <button type="submit" className="btn btn-primary">Save Changes</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Contact;
















// import React, { useState, useEffect, useContext } from "react";
// import { Navigate } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";
// import { fetchDataEndpoint, updateGuidanceData, addGuidanceData } from "../../api/StudentContact";
// import DocumentTitle from "../../components/DocumentTitle";

// const Contact = () => {
//   DocumentTitle("Royal College | Contact");

//   const { currentUser } = useContext(AuthContext);
//   const token = currentUser?.token;

//   const [guidanceData, setGuidanceData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     const fetchGuidanceData = async () => {
//       try {
//         if (currentUser) {
//           const guidanceData = await fetchDataEndpoint("/student/portal/contact", token);
//           setGuidanceData(guidanceData);
//           console.log('Guidance Data',guidanceData)
//         }
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchGuidanceData();
//   }, [currentUser, token]);

//   const handleUpdateGuidance = async (updatedGuidanceData) => {
//     try {
//       setLoading(true);
//       if (guidanceData.id) {
//         await updateGuidanceData(updatedGuidanceData, token);
//       } else {
//         await addGuidanceData(updatedGuidanceData, token);
//       }
//       const refreshedGuidanceData = await fetchDataEndpoint("/student/portal/contact", token);
//       setGuidanceData(refreshedGuidanceData);
//       setIsEditing(false);
//     } catch (err) {
//       setError(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   const handleAddNew = () => {
//     setGuidanceData({});
//     setIsEditing(true);
//   };

//   if (!currentUser) {
//     return <Navigate to="/login" replace />;
//   }

//   return (
//     <div className="container">
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error.message}</p>}
//       {guidanceData.id ? (
//         <div>
//           <ul>
//             <li>
//               <p>Name: {isEditing ? <input type="text" name="name" defaultValue={guidanceData.name} /> : guidanceData.name || "N/A"}</p>
//               <p>Email: {isEditing ? <input type="text" name="email" defaultValue={guidanceData.email} /> : guidanceData.email || "N/A"}</p>
//               <p>Contact : {isEditing ? <input type="text" name="contact" defaultValue={guidanceData.contact} /> : guidanceData.contact || "N/A"}</p>
//               <p>Relationship: {isEditing ? <input type="text" name="relationship" defaultValue={guidanceData.relationship} /> : guidanceData.relationship || "N/A"}</p>
//               <p>Address: {isEditing ? <input type="text" name="address" defaultValue={guidanceData.address} /> : guidanceData.address || "N/A"}</p>
             
//               {isEditing && <button onClick={() => setIsEditing(false)}>Save</button>}
//             </li>
//           </ul>
//           <div className="bg-light">
//             {!isEditing && <button onClick={handleEdit}>Edit Profile</button>}
//           </div>
//         </div>
//       ) : (
//         <div>
//           <p>No guidance information found. Please add emergency contact.</p>
//           <div className="bg-light">
//             <button onClick={handleAddNew}>Add New Guidance Information</button>
//           </div>
//         </div>
//       )}
//       {isEditing && (
//         <div>
//           <form onSubmit={(e) => {
//             e.preventDefault();
//             const updatedGuidanceData = {
//               name: e.target.name.value,
//               email: e.target.email.value,
//               contact: e.target.contact.value,
//               relationship: e.target.relationship.value,
//               address: e.target.address.value,


//             };
//             handleUpdateGuidance(updatedGuidanceData);
//           }}>
//             <div className="form-group">
//               <label htmlFor="name"> Name:</label>
//               <input type="text" className="form-control" id="name" name="name" defaultValue={guidanceData.name} />
//             </div>
//             <div className="form-group">
//               <label htmlFor="email"> Email:</label>
//               <input type="text" className="form-control" id="email" name="email" defaultValue={guidanceData.email} />
//             </div>
//             <div className="form-group">
//               <label htmlFor="contact">Contact:</label>
//               <input type="text" className="form-control" id="contact" name="contact" defaultValue={guidanceData.contact} />
//             </div>
//             <div className="form-group">
//               <label htmlFor="contact">Relationship:</label>
//               <input type="text" className="form-control" id="relationship" name="relationship" defaultValue={guidanceData.relationship} />
//             </div>
            
//             <div className="form-group">
//               <label htmlFor="address">Address:</label>
//               <input type="text" className="form-control" id="address" name="address" defaultValue={guidanceData.address} />
//             </div>


//             <button type="submit" className="btn btn-primary">Save Changes</button>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;
