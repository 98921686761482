import React from "react";
// import wins from '../assets/windows.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGithubSquare, faInstagramSquare, faLinkedin, faTelegramPlane, faTwitterSquare, faWhatsappSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import DocumentTitle from "../../components/DocumentTitle";



const termsData = [
    {
        // title: "Privacy policy ",
        title: "Terms and Condition ",

        intro: "Introduction",
        paragraph: "These terms and conditions ( Terms, Terms and Conditions) govern your relationship with the rise.com website (the Site) and the services provided by RISE (the Services). Please read these Terms carefully before using the Site and Services. By accessing or using the Site, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Site or use the Services.",

        points: [
            {
                t2: "Eligibility",
                desc: "You must be at least 18 years old to use the Site and Services. By using the Site and Services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.",
                details: [
                    {
                        id: '',
                        title: "User Accounts",
                        description: "To access certain features of the Site and Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account.",
                    },
                    {
                        id: '',
                        title: "Intellectual Property",
                        description: "The Site and its original content, features, and functionality are and will remain the exclusive property of RISE and its licensors. The Site is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of RISE."
                    },
                    {
                        id: '',
                        title: "Prohibited Activities",
                        description: (
                            <ul>
                                <p style={{marginLeft:"-20px"}}>You agree not to:</p>
                                <li className="li">Use the Site or Services for any unlawful purpose or in violation of any applicable law.</li>
                                <li className="li" >Interfere with or disrupt the Site or Services or servers or networks connected to the Site or Services.</li>
                                <li className="li">Use any data mining, robots, or similar data gathering or extraction methods.</li>
                                <li className="li">Attempt to gain unauthorized access to any portion of the Site or Services, other accounts, computer systems, or networks connected to the Site or Services, through hacking, password mining, or any other means. "</li>
                            </ul>
                        ),
                    },

                ]


            },

            {
                t2: "Payment Terms",
                desc: " We do not sell your valuable data to anybody, however, if neccessary, we may disclose your information in one or more of the following circumstance",
                details: [
                    {
                        id: '',
                        title: "Fees",
                        description: "Certain aspects of the Services may be subject to fees. All applicable fees are due immediately and are non-refundable, except as otherwise provided in these Terms or as required by law. RISE reserves the right to change its fees at any time, upon notice to you if such change may affect your existing subscriptions."
                    },
                    {
                        id: '',
                        title: "Payment Information",
                        description: "You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Site. You agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time."
                    },
                    {
                        title: "Termination",
                        description: "We may terminate or suspend your account and bar access to the Site and Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. If you wish to terminate your account, you may simply discontinue using the Site and Services."
                    },
                    {
                        title: "Limitation of Liability",
                        description: " no event shall RISE, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site or Services; (ii) any conduct or content of any third party on the Site or Services; (iii) any content obtained from the Site or Services; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage."
                    },

                    {
                        title: "Governing Law",
                        description: "These Terms shall be governed and construed in accordance with the laws of the Republic of Ghana, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Site and Services and supersede and replace any prior agreements we might have had between us regarding the Site and Services."
                    },
                    {
                        title: "Changes",
                        description: "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Site and Services after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Site and Services."
                    },
                    {
                        title: " Contact Us",
                        description: (
                            <span>
                                If you have any questions about these Terms, please contact us at{" "}
                                <a className="a" href="/support">click here</a>.
                            </span>
                        ),
                        // description: "If you have any questions about these Terms, please contact us at [Your Contact Information]."
                    }
                ]

            }



        ]
    }
];





const pageTitle = 'Terms-and-conditions';
const pageDescription = 'The Royal College of Science and Entrepreneurship (RISE) is all in one institution that seeks to provide opportunity for everyone to learn'


function Terms() {





    return (
        <div className="wrapper">
            <DocumentTitle title={pageTitle} description={pageDescription} />


            <div className='container mt-4 pb-5 mb-2'>
                {termsData.map((section, index) => (
                    <div className="contact-info" key={index}>
                        <h2 className='start'>{section.title}</h2>
                        <strong className='start'>{section.intro}</strong>
                        <p>{section.paragraph}</p>
                        <br />

                        {section.points.map((point, idx) => (
                            <div key={idx}>
                                <strong className='start'>{point.t2}</strong>
                                <p>{point.desc}</p>
                                <strong>{point.title}</strong>

                                {point.details.map((detail, dIdx) => (
                                    <div key={dIdx}>
                                        <strong > {detail.id} {detail.title}</strong>
                                        <p className='text-justify'>{detail.description}</p>
                                        <strong style={{}}>{detail.number}</strong>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>


        </div>
    );
}

export default Terms;



