// DetailPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
const API = process.env.REACT_APP_BASE_URL;


// import { useLocation } from 'react-router-dom';

const DetailPage = () => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
      // Function to fetch details based on ID
      const fetchDetails = async (id) => {
          try {
              const res = await axios.get(`${API}/openroute/admission-requirements/${id}`);
              setDetails(res.data);
              console.log(res)
          } catch (err) {
              setError('Error fetching details. Please try again later.');
              console.error('Error fetching details:', err);
          } finally {
              setLoading(false);
          }
      };

      // Extract ID from query string
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get('id');

      if (!id) {
          setError('No ID provided');
          setLoading(false);
          return;
      }

      fetchDetails(id);
  }, [location.search]);

  const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
  };

  return (
      <div className='background'>
          <section>
              <div className='container'>
                  {loading && <p>Loading details...</p>}
                  {error && <p>{error}</p>}
                  {!loading && !error && details ? (
                      <div className='pt-3'>
                          <button onClick={handleBack} className='btn btn-secondary'>Back</button>
                          <h1>{details.name}</h1>

                          <p>{details.details}</p>
                      </div>
                  ) : (
                      !loading && !error && <p>No details available</p>
                  )}
              </div>
          </section>
      </div>
  );
};

export default DetailPage;
