import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {ProgramData} from "../../api/ProgramController/ProgramData"
import DocumentTitle from "../../components/DocumentTitle";


const Program = () => {
  const { currentUser } = React.useContext(AuthContext);

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="container">
      <DocumentTitle title="Programs" />

      <ProgramData />
    </div>
  );
};

export default Program;



















// import React, { useState, useEffect, useContext } from 'react';
// import { AuthContext } from "../../context/AuthContext";
// import { fetchDataEndpoint } from '../../utils/fetch'; // Assuming fetchDataEndpoint is a utility function for fetching data
// import axios from 'axios'; // Import axios for making HTTP requests
// import { useLocation } from 'react-router-dom';

// const Program = () => {
//   const [userData, setUserData] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [selectedSubjects, setSelectedSubjects] = useState([]);

//   const { currentUser } = useContext(AuthContext);
//   const token = currentUser?.token;

//   const location = useLocation();
//   const isUpdateMode = location.search === "?-update";

//   useEffect(() => {
//     const fetchData = async () => {
//       if (currentUser) {
//         await fetchDataEndpoint("/student/portal/programs", token, setUserData, setError, setLoading);
//         // Fetch user's selected courses if in update mode
//         if (isUpdateMode) {
//           // Call endpoint to fetch user's selected courses
//           const userCourses = await fetchUserCourses();
//           setSelectedSubjects(userCourses);
//         }
//       }
//     };

//     fetchData();
//   }, [currentUser, token, isUpdateMode]);

//   const fetchUserCourses = async () => {
//     try {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };

//       // Make API call to fetch user's selected courses
//       const res = await axios.get(`${baseUrl}/student/portal/programs/courses`, config);
//       return res.data;
//     } catch (err) {
//       console.error("Error fetching user's courses:", err);
//       throw err;
//     }
//   };

//   const handleSubjectSelect = (subject) => {
//     // Check if the subject is already selected
//     const index = selectedSubjects.findIndex((selected) => selected.id === subject.id);
//     if (index === -1) {
//       // If not selected, add it to the selectedSubjects array
//       setSelectedSubjects([...selectedSubjects, subject]);
//     } else {
//       // If already selected, remove it from the selectedSubjects array
//       const updatedSelectedSubjects = [...selectedSubjects];
//       updatedSelectedSubjects.splice(index, 1);
//       setSelectedSubjects(updatedSelectedSubjects);
//     }
//   };

//   const postUserCourses = async () => {
//     try {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };

//       // Extract subject ID and category from selectedSubjects array
//       const coursesData = selectedSubjects.map(subject => ({
//         id: subject.id,
//         category: subject.category,
//       }));

//       // Use PUT or POST based on whether it's an update or not
//       const method = isUpdateMode ? "PUT" : "POST";

//       const res = await axios[method](`${baseUrl}/student/portal/programs`, coursesData, config);
//       console.log("Selected subjects submitted successfully:", res.data);
//     } catch (err) {
//       console.error("Error submitting selected subjects:", err);
//     }
//   };

//   const handleSubmit = () => {
//     // Display a popup for the user to confirm submission
//     if (window.confirm("Are you sure you want to submit your selected subjects?")) {
//       // Send the selectedSubjects array to the endpoint for submission
//       postUserCourses();
//     }
//   };

//   return (
//     <div className='container'>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error.message}</p>}

//       <h2>Select Subjects:</h2>

//       {userData["subjects[0]"] && userData["subjects[0]"].map(subject => (
//         <div key={subject.id}>
//           <label>
//             <input
//               type="checkbox"
//               value={subject.id}
//               onChange={() => handleSubjectSelect(subject)}
//               checked={selectedSubjects.some((selected) => selected.id === subject.id)}
//             />
//             {subject.code} - {subject.name}
//           </label>
//         </div>
//       ))}

//       <div>
//         <h2>Selected Subjects:</h2>
//         <ul>
//           {selectedSubjects.map((subject) => (
//             <li key={subject.id}>{subject.code} - {subject.name}</li>
//           ))}
//         </ul>
//       </div>

//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// };

// export default Program;
