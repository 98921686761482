// CompleteForm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Spinner, Alert, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const API = process.env.REACT_APP_BASE_URL;

function CompleteForm({ referenceNumber }) {
    const [formData, setFormData] = useState({
        firstname: '',
        middlename: '',
        surname: '',
        email: '',
        dob: '',
        contact: '',
        address: '',
        selectedCourses: []
    });
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Handle input changes for personal details
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle file input for image uploads
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    // Handle course selection
    const handleCourseChange = (e) => {
        const { options } = e.target;
        const selectedCourses = Array.from(options).filter(option => option.selected).map(option => option.value);
        setFormData({
            ...formData,
            selectedCourses
        });
    };

    // Submit function for personal data
    const handlePersonalDataSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        const formDataObj = new FormData();
        for (const key in formData) {
            formDataObj.append(key, formData[key]);
        }
        if (image) {
            formDataObj.append('image', image);
        }

        try {
            const response = await axios.post(`${API}/openroute/personal/${referenceNumber}`, formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setMessage(response.data.message);
            setTimeout(() => {
                document.getElementById('selectCoursesTab').click();
            }, 2000);
        } catch (err) {
            console.error('Error updating personal data:', err);
            setError('Failed to update personal data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Submit function for courses selection
    const handleCoursesSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        try {
            const response = await axios.post(`${API}/openroute/courses/${referenceNumber}`, { selectedCourses: formData.selectedCourses });
            setMessage(response.data.message);
            setTimeout(() => {
                navigate('/'); // Navigate to a different route after successful submission
            }, 2000);
        } catch (err) {
            console.error('Error updating courses:', err);
            setError('Failed to update courses. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="py-5">
            <h3 className="text-center mb-4">Complete Your Profile</h3>

            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            <Tabs defaultActiveKey="personalDetails" id="profile-tabs" className="mb-3">
                <Tab eventKey="personalDetails" title="Personal Details">
                    <Form onSubmit={handlePersonalDataSubmit}>
                        {/* Personal details fields */}
                        <Form.Group controlId="firstname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleInputChange}
                                required
                            />


                            <Form.Group controlId="middlename" className="mt-3">
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="middlename"
                                    value={formData.middlename}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="surname" className="mt-3">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="surname"
                                    value={formData.surname}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="email" className="mt-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="dob" className="mt-3">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="contact" className="mt-3">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="address" className="mt-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="image" className="mt-3">
                                <Form.Label>Profile Picture</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </Form.Group>



                        </Form.Group>
                        {/* Additional personal fields go here */}
                        <Button className="primary-button mt-4" type="submit" disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Save and continue'}
                        </Button>
                    </Form>
                </Tab>

                <Tab eventKey="selectCourses" title="Select Courses">
                    <Form onSubmit={handleCoursesSubmit}>
                        {/* Course selection */}
                        <Form.Group controlId="courses" className="mt-3">
                            <Form.Label>Select Your Courses</Form.Label>
                            <Form.Control
                                as="select"
                                multiple
                                name="selectedCourses"
                                value={formData.selectedCourses}
                                onChange={handleCourseChange}
                                required
                            >
                                <option value="course1">Course 1</option>
                                <option value="course2">Course 2</option>
                                <option value="course3">Course 3</option>
                                <option value="course4">Course 4</option>
                            </Form.Control>
                        </Form.Group>

                        <Button className="primary-button mt-4" type="submit" disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Submit Courses'}
                        </Button>
                    </Form>
                </Tab>
            </Tabs>
        </Container>
    );
}

export default CompleteForm;



// import React, { useState } from 'react';
// import axios from 'axios';
// import { Container, Form, Button, Spinner, Alert, Tab, Tabs } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';

// const API = process.env.REACT_APP_BASE_URL;

// function CompleteForm() {
//     const [formData, setFormData] = useState({
//         firstname: '',
//         middlename: '',
//         surname: '',
//         email: '',
//         dob: '',
//         contact: '',
//         address: '',
//         selectedCourses: []
//     });
//     const [image, setImage] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     // Handle input changes for personal details
//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     // Handle file input for image uploads
//     const handleImageChange = (e) => {
//         setImage(e.target.files[0]);
//     };

//     // Handle course selection
//     const handleCourseChange = (e) => {
//         const { options } = e.target;
//         const selectedCourses = Array.from(options).filter(option => option.selected).map(option => option.value);
//         setFormData({
//             ...formData,
//             selectedCourses
//         });
//     };

//     // Submit function for personal data
//     const handlePersonalDataSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError('');
//         setMessage('');

//         const formDataObj = new FormData();
//         for (const key in formData) {
//             formDataObj.append(key, formData[key]);
//         }
//         if (image) {
//             formDataObj.append('image', image);
//         }

//         try {
//             const response = await axios.post(`${API}/admission/personal/:reference`, formDataObj, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 }
//             });
//             setMessage(response.data.message);
//             setTimeout(() => {
//                 document.getElementById('selectCoursesTab').click();
//             }, 2000);
//         } catch (err) {
//             console.error('Error updating personal data:', err);
//             setError('Failed to update personal data. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Submit function for courses selection
//     const handleCoursesSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError('');
//         setMessage('');

//         try {
//             const response = await axios.post(`${API}/admission/courses/:reference`, { selectedCourses: formData.selectedCourses });
//             setMessage(response.data.message);
//             setTimeout(() => {
//                 navigate('/'); // Navigate to a different route after successful submission
//             }, 2000);
//         } catch (err) {
//             console.error('Error updating courses:', err);
//             setError('Failed to update courses. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <Container className="py-5">
//             <h3 className="text-center mb-4">Complete Your Profile</h3>

//             {message && <Alert variant="success">{message}</Alert>}
//             {error && <Alert variant="danger">{error}</Alert>}

//             <Tabs defaultActiveKey="personalDetails" id="profile-tabs" className="mb-3">
//                 <Tab eventKey="personalDetails" title="Personal Details">
//                     <Form onSubmit={handlePersonalDataSubmit}>
//                         {/* Personal details fields */}
//                         <Form.Group controlId="firstname">
//                             <Form.Label>First Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="firstname"
//                                 value={formData.firstname}
//                                 onChange={handleInputChange}
//                                 required
//                             />
//                         </Form.Group>
//                         {/* Additional personal fields go here */}
//                         <Button className="primary-button mt-4" type="submit" disabled={loading}>
//                             {loading ? <Spinner animation="border" size="sm" /> : 'Save and continue'}
//                         </Button>
//                     </Form>
//                 </Tab>

//                 <Tab eventKey="selectCourses" title="Select Courses">
//                     <Form onSubmit={handleCoursesSubmit}>
//                         {/* Course selection */}
//                         <Form.Group controlId="courses" className="mt-3">
//                             <Form.Label>Select Your Courses</Form.Label>
//                             <Form.Control
//                                 as="select"
//                                 multiple
//                                 name="selectedCourses"
//                                 value={formData.selectedCourses}
//                                 onChange={handleCourseChange}
//                                 required
//                             >
//                                 <option value="course1">Course 1</option>
//                                 <option value="course2">Course 2</option>
//                                 <option value="course3">Course 3</option>
//                                 <option value="course4">Course 4</option>
//                             </Form.Control>
//                         </Form.Group>

//                         <Button className="primary-button mt-4" type="submit" disabled={loading}>
//                             {loading ? <Spinner animation="border" size="sm" /> : 'Submit Courses'}
//                         </Button>
//                     </Form>
//                 </Tab>

//                 <Tab eventKey="preview" title="Preview">
//                     <Container className="mt-3">
//                         <h4>Review Your Details</h4>
//                         <pre>{JSON.stringify(formData, null, 2)}</pre>
//                         <Button className="primary-button mt-4" onClick={handleSubmit} disabled={loading}>
//                             {loading ? <Spinner animation="border" size="sm" /> : 'Submit All'}
//                         </Button>
//                     </Container>
//                 </Tab>
//             </Tabs>
//         </Container>
//     );
// }

// export default CompleteForm;


// // CompleteForm.jsx
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Container, Form, Button, Spinner, Alert, Tab, Tabs } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';

// const API = process.env.REACT_APP_BASE_URL;

// function CompleteForm() {
//     const [formData, setFormData] = useState({
//         firstname: '',
//         middlename: '',
//         surname: '',
//         email: '',
//         dob: '',
//         contact: '',
//         address: '',
//         selectedCourses: []
//     });
//     const [image, setImage] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const handleImageChange = (e) => {
//         setImage(e.target.files[0]);
//     };

//     const handleCourseChange = (e) => {
//         const { options } = e.target;
//         const selectedCourses = Array.from(options).filter(option => option.selected).map(option => option.value);
//         setFormData({
//             ...formData,
//             selectedCourses
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setError('');
//         setMessage('');

//         const formDataObj = new FormData();
//         for (const key in formData) {
//             formDataObj.append(key, formData[key]);
//         }
//         if (image) {
//             formDataObj.append('image', image);
//         }

//         try {
//             const response = await axios.post(`${API}/openroute//admission/personal/:reference`, formDataObj, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 }
//             });
//             setMessage(response.data.message);
//             setTimeout(() => {
//                 navigate('/'); // Navigate to a different route after successful submission
//             }, 2000);
//         } catch (err) {
//             console.error('Error updating profile:', err);
//             setError('Failed to update profile. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <Container className="py-5">
//             <h3 className="text-center mb-4">Complete Your Profile</h3>

//             {message && <Alert variant="success">{message}</Alert>}
//             {error && <Alert variant="danger">{error}</Alert>}

//             <Tabs defaultActiveKey="personalDetails" id="profile-tabs" className="mb-3">
//                 <Tab eventKey="personalDetails" title="Personal Details">
//                     <Form>
//                         {/* Personal details fields */}
//                         <Form.Group controlId="firstname">
//                             <Form.Label>First Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="firstname"
//                                 value={formData.firstname}
//                                 onChange={handleInputChange}
//                                 required
//                             />
//                         </Form.Group>
//                         {/* Additional personal fields go here */}
//                         <Button className="primary-button mt-4" type="button" onClick={() => document.getElementById('selectCoursesTab').click()}>
//                             Next: Select Courses
//                         </Button>
//                     </Form>
//                 </Tab>

//                 <Tab eventKey="selectCourses" title="Select Courses">
//                     <Form>
//                         {/* Course selection */}
//                         <Form.Group controlId="courses" className="mt-3">
//                             <Form.Label>Select Your Courses</Form.Label>
//                             <Form.Control
//                                 as="select"
//                                 multiple
//                                 name="selectedCourses"
//                                 value={formData.selectedCourses}
//                                 onChange={handleCourseChange}
//                                 required
//                             >
//                                 <option value="course1">Course 1</option>
//                                 <option value="course2">Course 2</option>
//                                 <option value="course3">Course 3</option>
//                                 <option value="course4">Course 4</option>
//                             </Form.Control>
//                         </Form.Group>

//                         <Button className="primary-button mt-4" type="button" onClick={() => document.getElementById('previewTab').click()}>
//                             Next: Preview
//                         </Button>
//                     </Form>
//                 </Tab>

//                 <Tab eventKey="preview" title="Preview">
//                     <Container className="mt-3">
//                         <h4>Review Your Details</h4>
//                         <pre>{JSON.stringify(formData, null, 2)}</pre>
//                         <Button className="primary-button mt-4" onClick={handleSubmit} disabled={loading}>
//                             {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
//                         </Button>
//                     </Container>
//                 </Tab>
//             </Tabs>
//         </Container>
//     );
// }

// export default CompleteForm;


// // import React, { useState } from 'react';
// // import axios from 'axios';
// // import { Container, Form, Button, Spinner, Alert, Tab, Tabs } from 'react-bootstrap';
// // import { useNavigate } from 'react-router-dom';

// // const API = process.env.REACT_APP_BASE_URL;

// // function CompleteForm() {
// //     const [formData, setFormData] = useState({
// //         firstname: '',
// //         middlename: '',
// //         surname: '',
// //         email: '',
// //         dob: '',
// //         contact: '',
// //         address: '',
// //         selectedCourses: []
// //     });
// //     const [image, setImage] = useState(null);
// //     const [loading, setLoading] = useState(false);
// //     const [message, setMessage] = useState('');
// //     const [error, setError] = useState('');
// //     const navigate = useNavigate();

// //     const handleInputChange = (e) => {
// //         const { name, value } = e.target;
// //         setFormData({
// //             ...formData,
// //             [name]: value
// //         });
// //     };

// //     const handleImageChange = (e) => {
// //         setImage(e.target.files[0]);
// //     };

// //     const handleCourseChange = (e) => {
// //         const { options } = e.target;
// //         const selectedCourses = Array.from(options).filter(option => option.selected).map(option => option.value);
// //         setFormData({
// //             ...formData,
// //             selectedCourses
// //         });
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         setLoading(true);
// //         setError('');
// //         setMessage('');

// //         const formDataObj = new FormData();
// //         for (const key in formData) {
// //             formDataObj.append(key, formData[key]);
// //         }
// //         if (image) {
// //             formDataObj.append('image', image);
// //         }

// //         try {
// //             const response = await axios.post(`${API}/openroute/admission/update-profile`, formDataObj, {
// //                 headers: {
// //                     'Content-Type': 'multipart/form-data',
// //                 }
// //             });
// //             setMessage(response.data.message);
// //             // Navigate back to the previous page or a success page
// //             setTimeout(() => {
// //                 navigate('/');
// //             }, 2000);
// //         } catch (err) {
// //             console.error('Error updating profile:', err);
// //             setError('Failed to update profile. Please try again later.');
// //         } finally {
// //             setLoading(false);
// //         }
// //     };

// //     return (
// //         <Container className="py-5">
// //             <h3 className="text-center mb-4">Complete Your Profile</h3>

// //             {message && <Alert variant="success">{message}</Alert>}
// //             {error && <Alert variant="danger">{error}</Alert>}

// //             <Tabs defaultActiveKey="personalDetails" id="profile-tabs" className="mb-3">
// //                 <Tab eventKey="personalDetails" title="Personal Details">
// //                     <Form>
// //                         <Form.Group controlId="firstname">
// //                             <Form.Label>First Name</Form.Label>
// //                             <Form.Control
// //                                 type="text"
// //                                 name="firstname"
// //                                 value={formData.firstname}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="middlename" className="mt-3">
// //                             <Form.Label>Middle Name</Form.Label>
// //                             <Form.Control
// //                                 type="text"
// //                                 name="middlename"
// //                                 value={formData.middlename}
// //                                 onChange={handleInputChange}
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="surname" className="mt-3">
// //                             <Form.Label>Surname</Form.Label>
// //                             <Form.Control
// //                                 type="text"
// //                                 name="surname"
// //                                 value={formData.surname}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="email" className="mt-3">
// //                             <Form.Label>Email</Form.Label>
// //                             <Form.Control
// //                                 type="email"
// //                                 name="email"
// //                                 value={formData.email}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="dob" className="mt-3">
// //                             <Form.Label>Date of Birth</Form.Label>
// //                             <Form.Control
// //                                 type="date"
// //                                 name="dob"
// //                                 value={formData.dob}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="contact" className="mt-3">
// //                             <Form.Label>Contact Number</Form.Label>
// //                             <Form.Control
// //                                 type="tel"
// //                                 name="contact"
// //                                 value={formData.contact}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="address" className="mt-3">
// //                             <Form.Label>Address</Form.Label>
// //                             <Form.Control
// //                                 type="text"
// //                                 name="address"
// //                                 value={formData.address}
// //                                 onChange={handleInputChange}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="image" className="mt-3">
// //                             <Form.Label>Profile Picture</Form.Label>
// //                             <Form.Control
// //                                 type="file"
// //                                 accept="image/*"
// //                                 onChange={handleImageChange}
// //                             />
// //                         </Form.Group>

// //                         <Button className="primary-button mt-4" type="button" onClick={() => document.getElementById('selectCoursesTab').click()}>
// //                             Next: Select Courses
// //                         </Button>
// //                     </Form>
// //                 </Tab>

// //                 <Tab eventKey="selectCourses" title="Select Courses">
// //                     <Form>
// //                         <Form.Group controlId="courses" className="mt-3">
// //                             <Form.Label>Select Your Courses</Form.Label>
// //                             <Form.Control
// //                                 as="select"
// //                                 multiple
// //                                 name="selectedCourses"
// //                                 value={formData.selectedCourses}
// //                                 onChange={handleCourseChange}
// //                                 required
// //                             >
// //                                 <option value="course1">Course 1</option>
// //                                 <option value="course2">Course 2</option>
// //                                 <option value="course3">Course 3</option>
// //                                 <option value="course4">Course 4</option>
// //                             </Form.Control>
// //                         </Form.Group>

// //                         <Button className="primary-button mt-4" type="button" onClick={() => document.getElementById('previewTab').click()}>
// //                             Next: Preview
// //                         </Button>
// //                     </Form>
// //                 </Tab>

// //                 <Tab eventKey="preview" title="Preview">
// //                     <Container className="mt-3">
// //                         <h4>Review Your Details</h4>
// //                         <pre>{JSON.stringify(formData, null, 2)}</pre>
// //                         <Button className="primary-button mt-4" onClick={handleSubmit} disabled={loading}>
// //                             {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
// //                         </Button>
// //                     </Container>
// //                 </Tab>
// //             </Tabs>
// //         </Container>
// //     );
// // }

// // export default CompleteForm;
