import React from 'react';
import './banner.css';

const Banner = ({ isVisible, onClose, message, title, ctaLabel, ctaUrl }) => {
  return (
    isVisible && (
      <div className="banner-overlay">
        <div className="banner">
          <div className='d-flex justify-content-between'>
            <h2>{title}</h2>
            <button className="secondary-button" onClick={onClose}>Close</button>
          </div>
          <div className='text-start mt-2'>
            <p>{message}</p>
          </div>
          {/* Render the CTA button if ctaLabel and ctaUrl are provided */}
          {ctaLabel && ctaUrl && (
            <div className="justify-content-start mt-3">
                <button className="primary-button">
                <a href={ctaUrl} >
                {ctaLabel}
              </a>

                </button>
             
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Banner;


// import React from 'react';
// import './banner.css'; // 
// const Banner = ({ isVisible, onClose, message, title }) => {
//   return (
//     isVisible && (
//       <div className="banner-overlay">
//         <div className="banner">
//             <div className='d-flex justify-content-between'>
//             <h2>{title}</h2>

//                 <button className="secondary-button" onClick={onClose}>Close</button>

//             </div>

          
//           <div className='text-start mt-2'>
//           <p>{message}</p>
//           </div>
          

//         </div>
//       </div>
//     )
//   );
// };

// export default Banner;
