import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {ProfileData} from "../../api/ProfileController/ProfileData"
import DocumentTitle from "../../components/DocumentTitle";


const Portal = () => {

  const { currentUser } = React.useContext(AuthContext);

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="container">
      <DocumentTitle title="Profile" />

      <h1>Profile Setting</h1>
      <ProfileData />
    </div>
  );
};

export default Portal;








// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const BASE_URL = process.env.REACT_APP_BASE_URL;

// // Create an instance of axios with the base URL
// const api = axios.create({
//   baseURL: BASE_URL,
// });

// function Profile() {
//   const [userData, setUserData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await api.get('/student/profile'); // Correct the API endpoint
//         setUserData(response.data);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserData();
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div>

//       <p>Profile Setting</p>
//       {/* Render profile data */}
//       {userData && (
//         <div>
//           <h2>Profile</h2>
//           <p>Name: {userData.name}</p>
//           <p>Email: {userData.email}</p>
//           {/* Add more profile data fields as needed */}
//         </div>
//       )}
//     </div>
//   );
// }

// export default Profile;
