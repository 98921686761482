import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';

const API = process.env.REACT_APP_BASE_URL;

const ReadMessages = () => {
    const { currentUser } = useContext(AuthContext);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams(); // Extract ID from URL path
    const navigate = useNavigate();

    // Redirect to login if user is not authenticated
    if (!currentUser) {
        return <Navigate to="/login" replace />;
    }

    useEffect(() => {
        const fetchDetails = async () => {
            if (!currentUser || !currentUser.token) {
                setError('Access denied. Token not provided.');
                setLoading(false);
                return;
            }
    
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${currentUser.token}`, // Include the token
                    },
                };
    
                const res = await axios.get(`${API}/student/message/${id}`, config);
                setMessage(res.data.message);
                console.log('Fetched message:', res.data);
            } catch (err) {
                setError('Error fetching message. Please try again later.');
                console.error('Error fetching message:', err);
            } finally {
                setLoading(false);
            }
        };
    
        if (id) {
            fetchDetails();
        } else {
            setError('No ID provided');
            setLoading(false);
        }
    }, [id, currentUser]);

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (

    <div className='background'>
    <section>
        <div className='container'>
            {loading && <p className='loading-message'>Loading details...</p>}
            {error && <p className='error-message'>{error}</p>}
            {!loading && !error && message ? (
                <div className='message-detail'>
                    <button onClick={handleBack} className='btn btn-secondary back-button'>Back</button>
                    <h1 className='message-title'>{message.message || 'Message Details'}</h1>
                    <p className='message-content'>{message.message || 'No message content available.'}</p>
                    <div className='message-meta'>
                        <p className='message-timestamp'>{new Date(message.timestamp).toLocaleString()}</p>
                        <p className='message-visibility'><strong>Visibility:</strong> {message.visibility}</p>
                    </div>
                </div>
            ) : (
                !loading && !error && <p className='no-details'>No details available</p>
            )}
        </div>
    </section>
</div>

    
    );
};

export default ReadMessages;
