import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { PortalData } from '../../api/PortalController/portalData';
import DocumentTitle from "../../components/DocumentTitle";


const Portal = () => {
  const { currentUser } = useContext(AuthContext);

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="container ">
      <DocumentTitle title="Portal" />
      <PortalData />
    </div>
  );
};

export default Portal;
