import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';

const API = process.env.REACT_APP_BASE_URL;

const CoursePage = () => {
  const { currentUser } = useContext(AuthContext);
  const [course, setCourse] = useState(null);
  const [popupType, setPopupType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { courseName } = useParams();
  const navigate = useNavigate();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  useEffect(() => {
    const fetchDetails = async () => {
      if (!currentUser.token) {
        setError('Access denied. Token not provided.');
        setLoading(false);
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const res = await axios.get(`${API}/student/courses/shs/${courseName}`, config);
        console.log('Fetched course:', res.data);

        if (res.data && res.data.course) {
          setCourse(res.data.course);
        } else {
          setError('Course not found');
        }
      } catch (err) {
        setError('Error fetching course. Please try again later.');
        console.error('Error fetching course:', err.response ? err.response.data : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [courseName, currentUser]);

  const handleBack = () => {
    navigate(-1);
  };

  const showPopup = (type) => {
    setPopupType(type);
  };

  const handleFeePayment = () => {
    showPopup('feePayment');
  };

  const handleSubmitAssignment = () => {
    showPopup('submitAssignment');
  };

  const closePopup = () => {
    setPopupType(null);
  };

  return (
    <div className='course-page'>
      <section>
        <div className='container'>
          {loading && <p className='loading-course'>Loading details...</p>}
          {error && <p className='error-course'>{error}</p>}
          {!loading && !error && course ? (
            <div className='course-hero-section'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <button onClick={handleBack} className='btn btn-secondary back-button'>Back</button>
                <p><strong>Instructor:</strong> Mawuli Stephen</p>
              </div>
              <div className="hero-content">
                <h1 className="course-name">{course.program_name || 'Course Details'}</h1>
                <p className='course-code'><strong>Category:</strong> {course.category || 'N/A'}</p>
                <p className='course-code'><strong>Course Code:</strong> {course.program_code || 'N/A'}</p>
                <p className="course-fees">Fees: $500</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: '70%' }}></div>
                </div>
                <p className="course-level">Level: 100</p>
                <p className='course-code'><strong>Level:</strong> {course.course_level || 'N/A'}</p>
                {/* <p className='course-code'><strong>Course Code:</strong> {course.acadamic_year_id || 'N/A'}</p> */}


                <hr />
                <p className="course-level">Assignments: 5</p>
              </div>
            </div>
          ) : (
            !loading && !error && <p className='no-details'>No details available</p>
          )}
        </div>

        <div>
          <div className='py-3 px-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <button onClick={handleFeePayment} className='btn btn-secondary back-button' disabled={loading}>Pay Fees</button>
            <button onClick={handleSubmitAssignment} className='btn btn-secondary back-button' disabled={loading}>Submit Assignment</button>
          </div>

          {popupType && (
            <div className='react-modal-overlay'>
              <div className='modal-content'>
                {popupType === 'feePayment' && <p>Fee payment form goes here...</p>}
                {popupType === 'submitAssignment' && <p>Assignment submission form goes here...</p>}
                <button onClick={closePopup} className='btn btn-close'>Close</button>
              </div>
            </div>
          )}
        </div>

        <h1>Introduction to course</h1>
      </section>
    </div>
  );
};

export default CoursePage;
