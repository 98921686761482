import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from '../../components/utility/carousel';
import DocumentTitle from '../../components/DocumentTitle';
import emma from '../../assets/emmanuel.jpg'

import { Link } from 'react-router-dom';
import VideoSlider from '../../components/utility/VideoSlider ';

const API = process.env.REACT_APP_BASE_URL;

const pageTitle = 'Online classes';
const pageDescription = 'The Royal College of Science and Entrepreneurship (RISE) aims to create an environment that promotes innovation, critical thinking, and the practical application of knowledge...';




function Onlineclass() {
    const videos = [
        'https://www.w3schools.com/html/mov_bbb.mp4',
        'https://www.w3schools.com/html/movie.mp4'
    ];
    // const [data, setData] = useState(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const res = await axios.get(`${API}/openroute/support`);
    //             setData(res.data);
    //             console.log('Fetched data:', res.data);
    //         } catch (err) {
    //             console.error('Error fetching data:', err);
    //         }
    //     };

    //     fetchData();
    // }, []); 

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${API}/openroute/admission-requirements`);
                const formattedData = res.data.map(item => ({
                    id: item.id, // Ensure ID is included for linking and is unique
                    title: item.name,
                    description: item.message
                }));

                setData(formattedData);
            } catch (err) {
                setError('Error fetching data. Please try again later.');
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='background'>
            <DocumentTitle title={pageTitle} description={pageDescription} />

            <section className='background-imag' style={{ background: 'red' }}>
                <div className="content-overlay">
                    <div className="container pt-5" >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h1>Smartest way to get <span style={{ color: 'tomato' }}>Educated.</span> Join us </h1>
                                <p>
                                    <br />
                                    Don't  slack, get taught by experienced teachers who have impacted thousonds of people.
                                    it's just your time to
                                    step into an environment where learning knows no bounds and excellence is the standard.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="container pb-2">
                        <div className="clipped d-flex justify-content-between">
                            <div className="clipped-element"></div>
                            <div className="clipped-element1"></div>
                            <div className="clipped-element2"></div>
                            <div className="clipped-element"></div>
                            <div className="clipped-element1"></div>
                            <div className="clipped-element2"></div>
                        </div>
                    </div>


                </div>
            </section>

            <section>
                <div className="container  pt-5" style={{ marginBottom: '100px' }}>
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-7">

                         
                            <div>
                                {/* <Carousel> */}

                                <VideoSlider videos={videos} />
                                {/* </Carousel> */}

                            </div>
                

                            <div className="button-group pt-3">
                                <button className='primary-button'><Link to={'/register'}>  Get Started</Link></button>
                                <button className='secondary-button'><Link to={'/about-us'}>Learn More</Link></button>
                            </div>


                        </div>






                        <div className="col-12 col-md-5 ">

                            <div>
                                {data.length > 0 ? (
                                    <>
                                        <div className='col-12 pt-5 pb-2 '>
                                            <div className='text-center'>
                                                {/* <h1>Wanna learn?</h1> */}
                                                <p>Find details of your preferred stage and its requirements</p>
                                            </div>
                                            {/* </div> */}

                                            {/* <div className='col-12'> */}
                                            <div className='d-flex flex-wrap justify-content-center'>
                                                {data.map((point) => (
                                                    <Link
                                                        key={point.id}
                                                        to={`/admission-requirements?id=${point.id}`} // Correct URL construction
                                                        className='text-decoration-none'
                                                    >
                                                        <div className='card-x'>
                                                            <h5>{point.title}</h5>

                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='container'>
                                        <p>No data available</p>
                                    </div>
                                )}
                            </div>


                       </div>

                    </div>
                </div>
            </section>







        </div>
    );
}

export default Onlineclass;
