import { Outlet } from "react-router-dom";
import TopNav from "./Topnav";
import Footer from "../../Footer"


const Layout = () => {
  return (
    <>

      <TopNav />

      <Outlet />

      <Footer />

    </>
  )
};
export default Layout