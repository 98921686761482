import React from "react";
import { AdmissionFormData } from "../../api/AdmissionFormController.js/admissionFormData";
import DocumentTitle from "../../components/DocumentTitle";
// export {AdmissionFormData}


const Forms = () => {

  return (
    <div className="container">
      <DocumentTitle title="Forms" />

      <AdmissionFormData/>

    </div>
  );
};

export default Forms;
