import React, { useState, useEffect, useContext } from 'react';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { AuthContext } from "../../context/AuthContext";
import fetchDataEndpoint from '../../utils/get'; // Import fetchDataEndpoint function
import DocumentTitle from '../../components/DocumentTitle';

const Payment = () => {
  const [userData, setuserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const token = currentUser?.token;

  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        if (currentUser) {
          // Call fetchDataEndpoint to fetch forms data
          await fetchDataEndpoint("/student/portal/form-status", token, setuserData, setError, setLoading);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchForms();
  }, [currentUser, token]);


  // const handleDownload = () => {
  //   const element = document.getElementById('receipt-container');

  //   html2canvas(element).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     const imgWidth = 210; // mm (A4 width)
  //     const pageHeight = imgWidth * 1.414; // A4 aspect ratio
  //     const imgHeight = canvas.height * imgWidth / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     pdf.save('receipt.pdf');
  //   });
  // };




  return (
    <div className='container'>
      <DocumentTitle title="Payment" />

      <div>
        {loading && <p>Loading...</p>}
        {error && <p>Error fetching userData: {error.message}</p>}

        {userData && userData.receipt && (
          <div className="">
            <br />

            <p>Amount: ${userData.receipt.amount}</p>
            <p>Status: {userData.receipt.status}</p>
            <p>Reference: {userData.receipt.reference}</p>
            <p>Receipt Number: {userData.receipt.receipt_number}</p>
            <p>Channel: {userData.receipt.channel}</p>
            <p>Currency: {userData.receipt.currency}</p>
            <p>Bank: {userData.receipt.bank}</p>
            {/* Optionally add links or further details */}
            {/* <button onClick={() => handleDownload(userData)}>Download</button> */}
          </div>
        )}





      </div>

    </div>
  );
};

export default Payment;
