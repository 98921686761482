import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DocumentTitle from '../../../components/DocumentTitle';
import { Link } from 'react-router-dom';
import Forms from '../openapi/forms/forms';
// import { Button, Card, Container } from 'react-bootstrap';
const API = process.env.REACT_APP_BASE_URL;

const pageTitle = 'Admission';
const pageDescription = 'The Royal Institute of Science and Entrepreneurship (RISE) aims to create an environment that promotes innovation, critical thinking, and the practical application of knowledge...';

function Admission() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${API}/openroute/admission-requirements`);
                const formattedData = res.data.map(item => ({
                    id: item.id, // Ensure ID is included for linking and is unique
                    title: item.name,
                    description: item.message
                }));

                setData(formattedData);
            } catch (err) {
                setError('Error fetching data. Please try again later.');
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='background'>
            <DocumentTitle title={pageTitle} description={pageDescription} />

            <section className='background-imag' style={{ background: 'yellow' }}>
                <div className="content-overlay">
                    <div className="container pt-5" >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h1>Everybody deserves to be <span style={{ color: 'tomato' }}>Educated.</span> It's your turn</h1>
                                <p>
                                    <br />
                                    Our admission is flexible for all who are willing to study; it's just your time to
                                    step into an environment where learning knows no bounds and excellence is the standard.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="container pb-2">
                        <div className="clipped d-flex justify-content-between">
                            <div className="clipped-element"></div>
                            <div className="clipped-element1"></div>
                            <div className="clipped-element2"></div>
                            <div className="clipped-element"></div>
                            <div className="clipped-element1"></div>
                            <div className="clipped-element2"></div>
                        </div>
                    </div>


                </div>
            </section>
            <section>
                <div>
                    {data.length > 0 ? (
                        <>
                            <div className='col-12 pt-5 pb-5 '>
                                <div className='text-center'>
                                    {/* <h1>Wanna learn?</h1> */}
                                    <p>Find details of your preferred stage and its requirements</p>
                                </div>
                                {/* </div> */}

                                {/* <div className='col-12'> */}
                                <div className='d-flex flex-wrap justify-content-center'>
                                    {data.map((point) => (
                                        <Link
                                            key={point.id}
                                            to={`/admission-requirements?id=${point.id}`} // Correct URL construction
                                            className='text-decoration-none'
                                        >
                                            <div className='card-x'>
                                                <h5>{point.title}</h5>

                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='container'>
                            {/* <p>No data available</p> */}
                        </div>
                    )}
                </div>
            </section>

          

            {/* Buy Admission Form Section */}
            {/* <section>
                <Container className="py-5">
                    <h3 className="text-center mb-4">Choose Your Admission Form</h3>
                    <div className="d-flex flex-wrap justify-content-center">
                        {admissionTypes.map((form) => (
                            <Card key={form.id} style={{ width: '18rem', margin: '10px' }}>
                                <Card.Body>
                                    <Card.Title>{form.title}</Card.Title>
                                    <Card.Text>{form.description}</Card.Text>
                                    <Link to={`/buy-form/${form.id}`}>
                                        <Button variant="primary">Buy {form.title} Form</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </Container>
            </section> */}

            <Forms/>

        </div>
    );
}

export default Admission;
