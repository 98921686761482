import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from '../../components/utility/carousel';
import DocumentTitle from '../../components/DocumentTitle';
import HeroSection from '../../components/utility/herosection/mobile';
// import { faImage } from '@fortawesome/free-solid-svg-icons';
// import emma from '../../assets/emmanuel.jpg';
import wins from '../../assets/windows.jpg';
import image from '../../assets/students.jpg';
// import waec from '../../assets/waec.png';

// import seth from '../assets/waec.jpg';  
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Tooltip from '../../components/tooltip/toolTip';
import Banner from '../../components/banner/banner';
import courseData from '../../components/data/courseData';
import contentData from '../../components/data/contentData';
// contentData
const API = process.env.REACT_APP_BASE_URL;


const pageTitle = 'Royal Institute of Science and Entrepreneurship | Home';
const pageDescription = 'The Royal College of Science and Entrepreneurship (RISE) aims to create an environment that promotes innovation, critical thinking, and the practical application of knowledge...';




const Home = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${API}`);
      setData(res.data);
      console.log(res.data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <div className='background' >
      <DocumentTitle title={pageTitle} description={pageDescription} />
      <div className='mobile'>
        <HeroSection />
      </div>



      <section className='background-image'>
        <div className="content-overlay">
          <div className="container desktop pt-5" style={{ marginBottom: '100px' }}>
            <div className="row">
              <div className="col-12 col-md-6">
                {/* Shape Your Future <br /> */}
                <h1>  Unlock Your Potential <br /> with  Excellence in <br /> <span style={{ color: 'tomato' }}>Education</span> </h1>

                <p>
                  We are dedicated to nurturing the leaders, innovators, and thinkers of tomorrow.
                  <br />
                  Step into an environment where learning knows no bounds and excellence is the standard.
                </p>
                <div className="button-group">
                  <button className='primary-button'><Link to={'/admission'}>  Get Started</Link></button>
                  <button className='secondary-button'><Link to={'/about-us'}>Learn More</Link></button>
                </div>
              </div>

              <div className="col-12 col-md-6 ">
                <div className='bg-dark' style={{ width: '70%', borderBottomRightRadius: '50%', borderTopLeftRadius: '40px' }}>

                </div>
                {/* <img style={{ width: '70%', borderBottomRightRadius: '50%', borderTopLeftRadius: '40px' }} src={image} alt="" /> */}
                <img style={{ width: '100%', borderBottomRightRadius: '40px', borderBottomRightRadius: '20%', borderTopLeftRadius: '20%' }} src={image} alt="" />
                {/* <img style={{ width: '30%', borderBottomRightRadius: '50%', borderTopRightRadius: '40px' }} src={wins} alt="" /> */}

                {/* <img style={{ width: '50%', borderBottomRightRadius: '50%', borderTopRightRadius: '40px' }} src={emma} alt="" /> */}



                <svg width="0" height="0">
                  <defs>
                    <clipPath id="myClip">
                      <img style={{ width: '30%', borderBottomRightRadius: '50%', borderTopRightRadius: '40px' }} src={wins} alt="" />

                      <circle cx="50" cy="50" r="50" />
                    </clipPath>
                  </defs>
                </svg>

                {/* <div className="clipped-element"></div> */}


              </div>
            </div>
          </div>



          <div className="container  pb-5">

            <div className="clipped d-flex justify-content-between">
              <div className="clipped-element"></div>
              <div className="clipped-element1"></div>
              <div className="clipped-element2"></div>
              <div className="clipped-element"></div>
              <div className="clipped-element1"></div>
              <div className="clipped-element2"></div>
            </div>


          </div>

          <div className='container pb-5 mb-2'>
            <div className="cx">
              {contentData.map((section, index) => (
                <div className='col-12' key={index}>
                  <h1 className='start'>{section.title} </h1>
                  <p>{section.paragraph}</p>

                  <div className='row-x'>
                    {/* <Carousel> */}
                    {section.points.map((point, idx) => (
                      <div key={idx} className='card-x bg-white'>
                        <h5>{point.title}</h5>
                        <p>{point.description}</p>
                      </div>
                    ))}
                    {/* </Carousel> */}
                  </div>

                </div>
              ))}


            </div>
          </div>
        </div>

      </section>

      <section className="bg-light py-5">
        <div className="container py-5">
          <div className="row flex-row-reverse">

            {/* Text Content */}
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <h1 className="mb-3">Become a part of</h1>
              <p className="mb-4">The thousands redefining their paths through Education.</p>

              {/* primary-button */}
              <button className='primary-button'><Link to={'/admission'}>  Get Started</Link></button>

            </div>

            {/* Visual Content */}
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <div className="clipped">
                <div className="clipped-element"></div>
                <div className="clipped-element1"></div>
                <div className="clipped-element2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 
        <section className=" bg-light p-5">
          <div className="container  p-5 m-4">
            <div className="row" style={{ flexDirection: 'row-reverse' }}>
              <div className="col-12 col-md-6">
                <h1>Become a part of <br /></h1>
                <p> the thousands redefining their paths through Education  </p>
                <br />

                <button className='primary-button'><Link to={'/register'}>  Get Started</Link></button>
              </div>

              <div className="col-12 col-md-6 centered">
                <div className="clipped ">
                  <div className="clipped-element"></div>
                  <div className="clipped-element1"></div>
                  <div className="clipped-element2"></div>
                </div>
              </div>
            </div>
          </div>
        </section> */}


      <section className="p-5 mt-4">
        <div className="container  pb-5">
          <div className="text-center">
            <div className="cx">
              {courseData.map((section, index) => (
                <div className='' key={index}>
                  <h1>{section.title}</h1>
                  <p>{section.paragraph}</p>

                  {/* <div className='row-x justify-content-center'>

                   
                      {section.points.map((point, idx) => (
                        <div key={idx} className='card-x'>
                          <h5>{point.title}</h5>
                          <hr />
                          <p className='text-start'>{point.description}</p>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center">
                            <p> Duration: {point.duration}</p>
                            <a href=""> More</a>
                          </div>

                        </div>
                      ))}
                     
                    </div> */}


                  {/* <div className="row justify-content-center">
                      {section.points.map((point, idx) => (
                        <div key={idx} className="col-12 col-lg-6 mb-4">
                          <Card className="shadow-card">
                            <Card.Body>
                              <Card.Title>{point.title}</Card.Title>
                              <hr />
                              <Card.Text>{point.description}</Card.Text>
                              <hr />
                              <div className="d-flex justify-content-between align-items-center">
                                <p>Duration: {point.duration}</p>
                                <a href="#">More</a>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ),)}
                    </div> */}

                  <div className="row justify-content-center">
                    {section.points.map((point, idx) => (
                      <div key={idx} className="col-12 col-lg-6 mb-4">
                        <Card className="shadow-card">
                          <Card.Body>
                            <div className="card-image-wrapper">
                              <img
                                src={point.image}
                                // seth
                                alt={point.title}
                                className="card-img-top mb-4"
                                style={{ objectFit: 'cover', width: '100%', height: '200px' }}
                              />
                            </div>
                            <Card.Title>{point.title}</Card.Title>
                            <hr />
                            <Card.Text>{point.description}</Card.Text>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">
                              <p>Duration: {point.duration}</p>
                              <a href="/admission">More</a>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>


                </div>
              ))}
            </div>



            {/* <div className="mt-4 justify-center">
                <button className='primary-button'><Link to={'/register'}></Link>Get Started</button>
              </div> */}

          </div>

        </div>
      </section>


      {/* <section className=" bg-light p-5">
          <div className="container  pb-4 mb-4">
            <div className="row" style={{ flexDirection: 'row' }}>
              <div className=" col-12 col-sm-12 ">
                <div className='d-flex justify-content-between'>
                  <h1>Realize  Your Purpose, <br /> Achieve Your Dreams   </h1>
                  <div className="clipped ">
                    <div className="clipped-element"></div>
                    <div className="clipped-element1"></div>
                    <div className="clipped-element2"></div>
                  </div>


                </div>

                <div className='line'>
                  <p >You have the chance to become one of the many hero's who came through us</p>

                </div>

                <div className='d-flex'>

                  <div className='card p-2 mr-4'>
                    <h2>200K { }</h2>
                    <span>Teachers</span>
                  </div>

                  <div className='card p-2 mr-4 '>
                    <h2>200K</h2>
                    <span>Students</span>
                  </div>

                  <div className='card p-2 mr-4'>
                    <h2>200K</h2>
                    <span>Students</span>
                  </div>

                </div>

              </div>

              <div className="col-12  col-sm-12">
                <div className="c">
                  {testimonyData.map((section, index) => (
                    <div className='' key={index}>
                      <div className=' justify-content-center'>
                        <Carousel>
                          {section.points.map((point, idx) => (
                            <div key={idx} className='card-x p-4 m-4'>
                              <h5>{point.name}</h5>
                              <img style={{ width: '100%', }} src={point.image} alt="" />


                              <p className='text-justify'>"{point.description}"</p>


                            </div>
                          ))}
                        </Carousel>
                      </div>

                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </section> */}


      {/* <div>
        <Tooltip text="Admission in progress">
          <span>WASSCE</span>
        </Tooltip>
        <Tooltip text="Admission in progress">
          <span>NOVDEC</span>
        </Tooltip>
      </div> */}

      {/* Banner component */}
      <Banner
        isVisible={isBannerVisible}
        onClose={handleCloseBanner}
        message="Welcome! Admission is now open for WASSCE and NOVDEC exams."
        title="Admission in Progress"
        ctaLabel="Enroll Now"
        ctaUrl="https://royaise.com/admission"

      />

    </div >
  );
}

export default Home;
