const contentData = [
    {
      title: "Why choose us ? ",
      paragraph: "Your Lifelong Journey of becoming... starts with us",
  
      points: [
        {
          title: "Expert Faculty:",
          description: "Learn from passionate educators who are experts in their fields and dedicated to your growth and success."
        },
        {
          title: "Comprehensive Curriculum:",
          description: "Explore a robust curriculum designed to foster critical thinking, creativity, and leadership skills, preparing you for a dynamic future."
        },
        {
          title: "Cutting-Edge Facilities:",
          description: "Access state-of-the-art facilities and resources that enhance your learning experience and support your academic and personal development."
        },
        {
          title: "Supportive Community:",
          description: "Join a diverse and inclusive community of students and mentors who will inspire and empower you to achieve your goals."
        }
      ]
    }
  ];

    
  // module.exports = contentData;
  export default  contentData