// AdmissionChecker.jsx
import React, { useState } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import FormModal from './formmodal';
const API = process.env.REACT_APP_BASE_URL;

function AdmissionChecker() {
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showCompleteForm, setShowCompleteForm] = useState(false);
    const [userData, setUserData] = useState(null); // Store user data for the form

    const handleCloseModal = () => {
        setShowModal(false);
        setStatusMessage('');
        setError(null);
    };

    const checkAdmissionStatus = async () => {
        setLoading(true);
        setError(null);
        setStatusMessage('');

        try {
            const response = await axios.post(`${API}/openroute/admission/check-status`, {
                emailOrPhone,
                referenceNumber,
            });

            if (response.data.redirectTo) {
                // Show the form if admission status is pending
                setStatusMessage(response.data.message);
                setShowCompleteForm(true);
            } else {
                setStatusMessage(`Admission Status: ${response.data.status}`);
            }
            setShowModal(true);
        } catch (err) {
            console.error('Error checking admission status:', err);
            setError('Unable to check admission status. Please try again later.');
            setShowModal(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!emailOrPhone || !referenceNumber) {
            setError('Please enter both your email/phone and reference number.');
            setShowModal(true);
            return;
        }
        checkAdmissionStatus();
    };

    return (
        <div className="auth">
            <div className="login">
                <Container className="py-5">
                    <h3 className="text-center mb-4">Check Your Admission Status</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="emailOrPhone">
                            <Form.Control
                                type="text"
                                placeholder="Enter your email or phone number"
                                value={emailOrPhone}
                                onChange={(e) => setEmailOrPhone(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="referenceNumber" className="mt-3">
                            <Form.Control
                                type="text"
                                placeholder="Enter your reference number"
                                value={referenceNumber}
                                onChange={(e) => setReferenceNumber(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button className="primary-button mt-4" type="submit" disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Check Admission Status'}
                        </Button>
                    </Form>
                </Container>
            </div>

            <FormModal
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                statusMessage={statusMessage}
                showCompleteForm={showCompleteForm}
                error={error}
                userData={userData}
                referenceNumber={referenceNumber} // Pass referenceNumber as a prop
            />
        </div>
    );
}

export default AdmissionChecker;


// // AdmissionChecker.jsx
// import React, { useState } from 'react';
// import { Container, Form, Button, Spinner } from 'react-bootstrap';
// import axios from 'axios';
// import FormModal from './formmodal';

// const API = process.env.REACT_APP_BASE_URL;

// function AdmissionChecker() {
//     const [emailOrPhone, setEmailOrPhone] = useState('');
//     const [referenceNumber, setReferenceNumber] = useState('');
//     const [statusMessage, setStatusMessage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [showCompleteForm, setShowCompleteForm] = useState(false);
//     const [userData, setUserData] = useState(null); // Store user data for the form

//     const handleCloseModal = () => {
//         setShowModal(false);
//         setStatusMessage('');
//         setError(null);
//     };

//     const checkAdmissionStatus = async () => {
//         setLoading(true);
//         setError(null);
//         setStatusMessage('');

//         try {
//             const response = await axios.post(`${API}/openroute/admission/check-status`, {
//                 emailOrPhone,
//                 referenceNumber,
//             });

//             if (response.data.redirectTo) {
//                 // Show the form if admission status is pending
//                 setStatusMessage(response.data.message);
//                 setShowCompleteForm(true);
//             } else {
//                 setStatusMessage(`Admission Status: ${response.data.status}`);
//             }
//             setShowModal(true);
//         } catch (err) {
//             console.error('Error checking admission status:', err);
//             setError('Unable to check admission status. Please try again later.');
//             setShowModal(true);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!emailOrPhone || !referenceNumber) {
//             setError('Please enter both your email/phone and reference number.');
//             setShowModal(true);
//             return;
//         }
//         checkAdmissionStatus();
//     };

//     return (
//         <div className="auth">
//             <div className="login">
//                 <Container className="py-5">
//                     <h3 className="text-center mb-4">Check Your Admission Status</h3>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group controlId="emailOrPhone">
//                             <Form.Control
//                                 type="text"
//                                 placeholder="Enter your email or phone number"
//                                 value={emailOrPhone}
//                                 onChange={(e) => setEmailOrPhone(e.target.value)}
//                                 required
//                             />
//                         </Form.Group>

//                         <Form.Group controlId="referenceNumber" className="mt-3">
//                             <Form.Control
//                                 type="text"
//                                 placeholder="Enter your reference number"
//                                 value={referenceNumber}
//                                 onChange={(e) => setReferenceNumber(e.target.value)}
//                                 required
//                             />
//                         </Form.Group>

//                         <Button className="primary-button mt-4" type="submit" disabled={loading}>
//                             {loading ? <Spinner animation="border" size="sm" /> : 'Check Admission Status'}
//                         </Button>
//                     </Form>
//                 </Container>
//             </div>

//             <FormModal
//                 showModal={showModal}
//                 handleCloseModal={handleCloseModal}
//                 statusMessage={statusMessage}
//                 showCompleteForm={showCompleteForm}
//                 error={error}
//                 userData={userData}
//             />
//         </div>
//     );
// }

// export default AdmissionChecker;


// // // AdmissionChecker.jsx
// // import React, { useState } from 'react';
// // import { Container, Form, Button, Spinner } from 'react-bootstrap';
// // import axios from 'axios';
// // import FormModal from './formmodal';

// // const API = process.env.REACT_APP_BASE_URL;

// // function AdmissionChecker() {
// //     const [emailOrPhone, setEmailOrPhone] = useState('');
// //     const [referenceNumber, setReferenceNumber] = useState('');
// //     const [statusMessage, setStatusMessage] = useState('');
// //     const [loading, setLoading] = useState(false);
// //     const [error, setError] = useState(null);
// //     const [showModal, setShowModal] = useState(false);
// //     const [showCompleteForm, setShowCompleteForm] = useState(false);

// //     const handleCloseModal = () => {
// //         setShowModal(false);
// //         setStatusMessage('');
// //         setError(null);
// //     };

// //     const checkAdmissionStatus = async () => {
// //         setLoading(true);
// //         setError(null);
// //         setStatusMessage('');

// //         try {
// //             const response = await axios.post(`${API}/openroute/admission/check-status`, {
// //                 emailOrPhone,
// //                 referenceNumber,
// //             });

// //             if (response.data.redirectTo) {
// //                 setStatusMessage(response.data.message);
// //                 setShowCompleteForm(true);
// //             } else {
// //                 setStatusMessage(`Admission Status: ${response.data.status}`);
// //             }
// //             setShowModal(true);
// //         } catch (err) {
// //             console.error('Error checking admission status:', err);
// //             setError('Unable to check admission status. Please try again later.');
// //             setShowModal(true);
// //         } finally {
// //             setLoading(false);
// //         }
// //     };

// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         if (!emailOrPhone || !referenceNumber) {
// //             setError('Please enter both your email/phone and reference number.');
// //             setShowModal(true);
// //             return;
// //         }
// //         checkAdmissionStatus();
// //     };

// //     return (
// //         <div className="auth">
// //             <div className="login">
// //                 <Container className="py-5">
// //                     <h3 className="text-center mb-4">Check Your Admission Status</h3>
// //                     <Form onSubmit={handleSubmit}>
// //                         <Form.Group controlId="emailOrPhone">
// //                             <Form.Control
// //                                 type="text"
// //                                 placeholder="Enter your email or phone number"
// //                                 value={emailOrPhone}
// //                                 onChange={(e) => setEmailOrPhone(e.target.value)}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Form.Group controlId="referenceNumber" className="mt-3">
// //                             <Form.Control
// //                                 type="text"
// //                                 placeholder="Enter your reference number"
// //                                 value={referenceNumber}
// //                                 onChange={(e) => setReferenceNumber(e.target.value)}
// //                                 required
// //                             />
// //                         </Form.Group>

// //                         <Button className="primary-button mt-4" type="submit" disabled={loading}>
// //                             {loading ? <Spinner animation="border" size="sm" /> : 'Check Admission Status'}
// //                         </Button>
// //                     </Form>
// //                 </Container>
// //             </div>

// //             <FormModal
// //                 showModal={showModal}
// //                 handleCloseModal={handleCloseModal}
// //                 statusMessage={statusMessage}
// //                 showCompleteForm={showCompleteForm}
// //                 error={error}
// //             />
// //         </div>
// //     );
// // }

// // export default AdmissionChecker;
