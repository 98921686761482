// FormModal.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './FormModal.css';
import CompleteForm from './CompleteForm';

const FormModal = ({ showModal, handleCloseModal, statusMessage, showCompleteForm, error, referenceNumber }) => {
    if (!showModal) return null;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal-container">
                <div className="custom-modal-header">
                    <h2>Admission Status</h2>
                    <button className="close-button" onClick={handleCloseModal}>×</button>
                </div>
                <div className="custom-modal-body">
                    {statusMessage && <div className="alert alert-success">{statusMessage}</div>}
                    {showCompleteForm && <CompleteForm referenceNumber={referenceNumber} />}
                    {error && <div className="alert alert-danger">{error}</div>}
                </div>
                <div className="custom-modal-footer">
                    <button className="close-button-footer" onClick={handleCloseModal}>Close</button>
                </div>
            </div>
        </div>
    );
};

FormModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    statusMessage: PropTypes.string,
    showCompleteForm: PropTypes.bool,
    error: PropTypes.string,
    referenceNumber: PropTypes.string, // Prop type for referenceNumber
};

export default FormModal;


// // FormModal.jsx
// import React from 'react';
// import PropTypes from 'prop-types';
// import './FormModal.css';
// import CompleteForm from './CompleteForm';

// const FormModal = ({ showModal, handleCloseModal, statusMessage, showCompleteForm, error }) => {
//     if (!showModal) return null;

//     return (
//         <div className="custom-modal-overlay">
//             <div className="custom-modal-container">
//                 <div className="custom-modal-header">
//                     <h2>Admission Status</h2>
//                     <button className="close-button" onClick={handleCloseModal}>×</button>
//                 </div>
//                 <div className="custom-modal-body">
//                     {statusMessage && <div className="alert alert-success">{statusMessage}</div>}
//                     {showCompleteForm && <CompleteForm />}
//                     {error && <div className="alert alert-danger">{error}</div>}
//                 </div>
//                 <div className="custom-modal-footer">
//                     <button className="close-button-footer" onClick={handleCloseModal}>Close</button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// FormModal.propTypes = {
//     showModal: PropTypes.bool.isRequired,
//     handleCloseModal: PropTypes.func.isRequired,
//     statusMessage: PropTypes.string,
//     showCompleteForm: PropTypes.bool,
//     error: PropTypes.string,
// };

// export default FormModal;


// // // FormModal.jsx
// // import React from 'react';
// // import PropTypes from 'prop-types';
// // import './FormModal.css'; // Import a CSS file for custom styling
// // import CompleteForm from './CompleteForm'; // Import only here

// // const FormModal = ({ showModal, handleCloseModal, statusMessage, showCompleteForm, error }) => {
// //     if (!showModal) return null; // Don't render anything if the modal is not shown

// //     return (
// //         <div className="custom-modal-overlay">
// //             <div className="custom-modal-container">
// //                 <div className="custom-modal-header">
// //                     <h2>Admission Status</h2>
// //                     <button className="close-button" onClick={handleCloseModal}>×</button>
// //                 </div>
// //                 <div className="custom-modal-body">
// //                     {statusMessage && <div className="alert alert-success">{statusMessage}</div>}
// //                     {showCompleteForm && <CompleteForm />}
// //                     {error && <div className="alert alert-danger">{error}</div>}
// //                 </div>
// //                 <div className="custom-modal-footer">
// //                     <button className="close-button-footer" onClick={handleCloseModal}>Close</button>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // // Prop validation
// // FormModal.propTypes = {
// //     showModal: PropTypes.bool.isRequired,
// //     handleCloseModal: PropTypes.func.isRequired,
// //     statusMessage: PropTypes.string,
// //     showCompleteForm: PropTypes.bool,
// //     error: PropTypes.string,
// // };

// // export default FormModal;
