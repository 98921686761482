import "./css/style.css";
import "./css/stylesdashboard.css";
import "./css/modal.css";
// import "./css/videoslider.css";
import "./css/carousel.css";
import "./css/navigation.css";
import "./css/userd.css";


import Layout from "./components/layout/openroute/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//opoen routes | No password protection

import Home from "./pages/openroutes/Home";
import Support from "./pages/openroutes/Support";
import About from "./pages/openroutes/About";

import Login from "./pages/openroutes/Login";
import Register from "./pages/openroutes/Register";
import Forgot from "./pages/openroutes/ForgotPassword";
import Reset from "./pages/openroutes/ResetPassword";

import Terms from "./pages/openroutes/Terms";
import Privacy from "./pages/openroutes/Policy";
// import Admission from "./pages/openroutes/admission/admission";
import Admission from "./pages/openroutes/admission/admission";
import DetailPage from "./pages/openroutes/admission/Adm-details";

import Onlineclass from "./pages/openroutes/Onlineclass";


import Unauthorized from './components/Unauthorized';
import Missing from './components/404';


// Student Portal Pages | Password protected
import Dashboard from "./components/layout/closedroute/Dashboard";
import Portal from './pages/student/Portal';
import Profile from './pages/student/Profile';
import Payment from './pages/student/Payment';
import Forms from './pages/student/Form';
import Contact from './pages/student/Contact';
import Programs from './pages/student/Programs';
import ReadMessages from "./pages/student/Messages";
import CoursePage from "./pages/student/coursebyId";
import AdmissionChecker from "./pages/openroutes/admission/admissionchecker";
import Reference from "./pages/openroutes/reference";


function App() {
  return (
    
  <Router>
    <Routes>
      {/* Routes for non-authenticated users */}

        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/admission-status" element={<AdmissionChecker />} />
          <Route path="/success/:reference" element={<Reference />} />
          {/* ?trxref=reference&reference=reference */}
          <Route path="/admission-requirements" element={<DetailPage />} />

          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/online-classes" element={<Onlineclass />} />


          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<Missing />} />
        </Route>
  

      {/* Routes for authenticated users */}

        <Route element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/portal/profile" element={<Profile />} />
          {/* <Route path="/portal/admission-forms" element={<Form />} /> */}
          <Route path="/portal/payment" element={<Payment />} />
          <Route path="/portal/contact" element={<Contact />} />
          <Route path="/portal/programs" element={<Programs />} />
          <Route path="/portal/form" element={<Forms />} />
          <Route path="/portal/read-messages/:id" element={<ReadMessages />} />

          {/* Course by Id */}
          {/* <Route path="portal/courses/shs/:id" component={<CoursePage/>} /> */}
          {/* <Route path="/portal/courses/shs/:id" element={<CoursePage />} /> */}
          <Route path="/portal/courses/shs/:courseName" element={<CoursePage />} />




          <Route path="/portal/*" element={<Missing />} />
        </Route>
   

          </Routes>
  </Router>
);
}

export default App;
