// coursesData.js
import waec from '../../assets/waec.png';


const courseData = [
  {
    title: "Available Courses",
    paragraph: "Your Lifelong Journey of becoming... starts with us",
    points: [
      {
        title: "WASSCE",
        image: `${waec}`,
        description: "We recognize the pivotal role of the West African Senior School Certificate Examination (WASSCE) in shaping students' academic futures. As part of that, we provide tuition in all examinable subjects by WAEC for students seeking to leverage technology to learn.",
        duration: "3 years"
      },
      {
        title: "NOV/DEC",
        image: `${waec}`,
        description: "Explore the options of improving your academic credentials, an important option to meet the entry requirement for higher education in most tertiary institutions. Stay home, get taught and write the WASSCE private examination.",
        duration: "1 year"
      }
    ]
  }
];

// module.exports = courseData;
export default courseData;
